import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import PlayIcon from '@material-ui/icons/PlayArrow'
import Hidden from '@material-ui/core/Hidden'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import BoxMargin from '~/components/BoxMargin'
import BoxPadding from '~/components/BoxPadding'
import Typo from '~/components/Typo'
import { getCollection } from '~/queries'
import useCollectionPlayer from './useCollectionPlayer'
import { useTranslation } from '~/i18n'
import clsx from 'clsx'

interface Props {
  globalId: string
  data?: getCollection | null
}
const TITLE_MARGIN_TOP = 100
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    position: 'relative',
  },
  body: {
    marginTop: TITLE_MARGIN_TOP,
    marginBottom: TITLE_MARGIN_TOP,
  },
  title: {
    fontSize: '4rem',
    maxWidth: 640,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.4rem',
    },
  },
  subtitle: {
    marginTop: theme.spacing(2),
    fontSize: '1.5rem',
    maxWidth: 640,
  },
  playAllButtonBigScreen: {
    position: 'absolute',
    left: -50,
    top: `calc(${TITLE_MARGIN_TOP}px + 15px)`,
  },
  // modifiers
  hasBackground: {
    '& $title, & $subtitle': {
      color: 'white',
    },
    '&:before': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.3,
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
}))

const Header = ({ globalId, data }: Props) => {
  const classes = useStyles()
  const { playCollection } = useCollectionPlayer({ objectId: globalId })
  const { t } = useTranslation('collection')
  const backgroundUrl = data?.collection?.background?.renditions?.big?.href
  return (
    <div
      className={clsx(classes.root, {
        [classes.hasBackground]: !!backgroundUrl,
      })}
      style={{
        backgroundImage: backgroundUrl
          ? `url(${data?.collection?.background?.renditions?.big?.href})`
          : undefined,
      }}
    >
      <BoxMargin minHeight="50vh">
        <BoxPadding position="relative">
          <Hidden smDown>
            <div className={classes.playAllButtonBigScreen}>
              <Tooltip title={t('collection:PLAY_ALL_TOOLTIP') || ''}>
                <Fab
                  size="large"
                  color="primary"
                  variant={'round'}
                  onClick={playCollection}
                >
                  <PlayIcon />
                </Fab>
              </Tooltip>
            </div>
          </Hidden>
          <div className={classes.body}>
            <Typo variant="storyHeadline" className={classes.title}>
              {data?.collection?.name}
            </Typo>
            {data?.collection?.subtitle && (
              <div>
                <Typography className={classes.subtitle}>
                  {data?.collection?.subtitle}
                </Typography>
              </div>
            )}
          </div>
        </BoxPadding>
      </BoxMargin>
    </div>
  )
}

export default Header

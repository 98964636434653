import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Grid, { GridSize } from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { getCollectionEvents, getCollectionEventsVariables } from '~/queries'
import EventItem from '~/components/EventItem'
import Typo from '~/components/Typo'
import { useTranslation } from '~/i18n'
import {
  TopDisappearingObserver,
  TopAppearingObserver,
} from '~/components/Observers'
import useMap from './useMap'
import useWidthRelatedToMap from '~/hooks/useWidthRelatedToMap'
import eventFrag from '~/queries/eventFrag'
import Button from '@material-ui/core/Button'
import Grow from '@material-ui/core/Grow'

interface Props {
  collectionGlobalId: string
  past?: boolean
}

function Events({ collectionGlobalId, past }: Props) {
  const [show, setShow] = React.useState(!past)
  const [activeEvent, setActiveEvent] = React.useState<string>()

  const { t } = useTranslation('collection')
  const size = useWidthRelatedToMap()
  const { data } = useQuery<getCollectionEvents, getCollectionEventsVariables>(
    eventsQuery,
    { variables: { collectionId: collectionGlobalId, past } }
  )
  const { loadMap, focusOn } = useMap(
    `text=${collectionGlobalId}&post_type=event`
  )

  React.useEffect(() => {
    loadMap()
  }, [])

  React.useEffect(() => {
    if (activeEvent) {
      focusOn(activeEvent)
    }
  }, [activeEvent, focusOn])

  /** Focus on the first event at the begining  */
  React.useEffect(() => {
    if (data?.collection?.events?.edges[0]?.node?.id) {
      focusOn(data.collection.events.edges[0].node?.id)
    }
  }, [data, focusOn])

  if (
    data?.collection?.events?.edges &&
    data?.collection?.events?.edges.length === 0
  ) {
    return null
  }
  if (!show) {
    return (
      <Box mb={10} textAlign="center">
        <Button
          size="large"
          variant="outlined"
          color="primary"
          onClick={() => setShow(true)}
        >
          {t('collection:PAST_EVENTS_SHOW_BUTTON_LABEL')}
        </Button>
      </Box>
    )
  }
  return (
    <Grow mountOnEnter in={show}>
      <Box mb={10}>
        <TopDisappearingObserver onChange={loadMap} />
        <Box mb={2}>
          {past ? (
            <Typo variant="sectionStoryTitle">
              {t('collection:PAST_EVENTS_TITLE')}
            </Typo>
          ) : (
            <Typo variant="sectionStoryTitle">
              {t('collection:EVENTS_TITLE')}
            </Typo>
          )}
        </Box>
        <Grid container spacing={1}>
          {data?.collection?.events?.edges.map((edge) => (
            <Grid item {...(size ? GRID[size] : {})} key={edge?.node?.id}>
              <EventItem
                onMouseEnter={() => setActiveEvent(edge?.node?.id)}
                id={edge?.node?.id}
                name={edge?.node?.name}
                artwork={edge?.node?.thumbnail}
                startDatetime={edge?.node?.startDatetime}
                endDatetime={edge?.node?.endDatetime}
                locationName={edge?.node?.locationName}
                author={edge?.node?.authorFreetext}
                active={activeEvent === edge?.node?.id}
              />
            </Grid>
          ))}
        </Grid>
        <TopAppearingObserver onChange={loadMap} />
      </Box>
    </Grow>
  )
}

export const eventsQuery = gql`
  query getCollectionEvents($collectionId: ID!, $past: Boolean) {
    collection(id: $collectionId) {
      id
      events(orderBy: "startDatetime", past: $past) {
        edges {
          node {
            ...EventItem
          }
        }
      }
    }
  }
  ${eventFrag}
`

type GridSizes = {
  xs?: GridSize
  sm?: GridSize
  md?: GridSize
  lg?: GridSize
  xl?: GridSize
}
const GRID: {
  xs: GridSizes
  sm: GridSizes
  md: GridSizes
  lg: GridSizes
  xl: GridSizes
} = {
  xs: {
    xs: 12,
  },
  sm: {
    xs: 12,
    sm: 6,
  },
  md: {
    xs: 12,
    sm: 6,
    md: 4,
  },
  lg: {
    xs: 12,
    sm: 6,
    md: 3,
  },
  xl: {
    xs: 12,
    sm: 6,
    md: 3,
  },
}

export default Events

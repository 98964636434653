import gql from 'graphql-tag'

export default gql`
  query getCollection($globalId: ID!) {
    collection(id: $globalId) {
      id
      name
      subtitle
      body
      background {
        id
        renditions {
          big {
            id
            href
            width
            height
          }
        }
      }
      createdBy {
        id
        slug
        displayName
        avatar
      }
    }
  }
`

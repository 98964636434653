import { Theme, useTheme } from '@material-ui/core/styles'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { useSelector } from '~/reducers'
import { MAP_WIDTHS } from '~/constantes'
import useWindowSize from './useWindowSize'
import React from 'react'

type BreakpointOrNull = Breakpoint | null

/**
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
export default function useWidthRelatedToMap(): Breakpoint | null {
  const theme: Theme = useTheme()
  const mapSize = useSelector((state) => state.map.size)
  const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse()
  const { width } = useWindowSize()
  const [res, setRes] = React.useState<Breakpoint | null>(null)

  React.useEffect(() => {
    if (width) {
      let res =
        keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
          const matches =
            width &&
            width >
              theme.breakpoints.values[key] +
                (MAP_WIDTHS[mapSize] / 100) * width
          return !output && matches ? key : output
        }, null) || 'xs'
      setRes(res)
    }
  }, [width, keys, mapSize, theme.breakpoints.values])

  return res
}

import React from 'react'
import CollectionPage from '~/components/Collection/CollectionPage'
import Layout from '~/components/Layout'

interface Props {
  globalId: string
}

const Collection = ({ globalId }: Props) => {
  return (
    <Layout>
      <CollectionPage globalId={globalId} />
    </Layout>
  )
}

Collection.getInitialProps = async ({ query }: any) => {
  return { globalId: query.oid, namespacesRequired: ['common', 'collection'] }
}

export default Collection

import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { searchForPlaylist, searchForPlaylistVariables } from '~/queries'
import Item from '~/components/Item'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import PostsPlaylist from '~/components/PostsPlaylist'
import gql from 'graphql-tag'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useDispatch } from 'react-redux'
import { playAndSetPlaylist } from '~/ducks/player'
import { useTranslation } from '~/i18n'

interface Props {
  entityId: string
  playlistTitle: string
  ordering?: string
  limit?: number
  title?: React.ReactNode
  wrapper?: React.ElementType
}
const qs = gql`
  query searchForPlaylist(
    $term: String!
    $offset: Int!
    $ordering: String
    $limit: Int!
  ) {
    search(
      term: $term
      postType: "media"
      limit: $limit
      offset: $offset
      ordering: $ordering
    ) {
      hasMore
      count
      results {
        id
        slug
        postType
        type
        name
        duration
        thumbnail
        lng
      }
    }
  }
`

const Playlist = ({
  entityId,
  playlistTitle,
  ordering,
  limit = 10,
  title,
  wrapper,
}: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const { data, fetchMore, loading } = useQuery<
    searchForPlaylist,
    searchForPlaylistVariables
  >(qs, { variables: { term: entityId, offset: 0, ordering, limit } })
  if (loading) {
    return (
      <Box my={4}>
        <CircularProgress variant="indeterminate" />
      </Box>
    )
  }
  if (!data || data.search?.results!.length < 1) {
    return null
  }
  const loadMore = () => {
    fetchMore({
      variables: {
        offset: data.search?.results!.length,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (
          !fetchMoreResult ||
          data !== previousResult ||
          !previousResult.search?.results ||
          !fetchMoreResult?.search?.results
        )
          return previousResult
        return {
          search: {
            ...previousResult.search,
            hasMore: fetchMoreResult.search.hasMore,
            count: fetchMoreResult.search.count,
            results: [
              ...previousResult.search?.results!,
              ...fetchMoreResult?.search?.results!,
            ],
          },
        }
      },
    })
  }
  const postsIds = data.search?.results.map((r) => r?.id) as string[]
  const Wrapper = wrapper || 'div'
  return (
    <Wrapper>
      {title && <Box mb={3}>{title}</Box>}
      {data.search?.results!.length < 4 ? (
        <Grid container spacing={5}>
          {data?.search?.results.map((media, idx) => (
            <Grid item key={media?.id} xs={3}>
              {media && (
                <Item
                  globalId={media.id}
                  thumbnail={media.thumbnail}
                  slug={media.slug}
                  name={media.name}
                  duration={media.duration}
                  onPlayClick={() =>
                    dispatch(
                      playAndSetPlaylist({
                        objectId: media?.id,
                        playlistGlobalId: entityId,
                        playlistTitle: playlistTitle,
                        playlist: postsIds.slice(idx + 1),
                      })
                    )
                  }
                />
              )}
            </Grid>
          ))}
        </Grid>
      ) : (
        <div>
          <PostsPlaylist
            onPlay={(mediaId, idx) => {
              dispatch(
                playAndSetPlaylist({
                  objectId: mediaId,
                  playlistGlobalId: entityId,
                  playlistTitle: playlistTitle,
                  playlist: postsIds.slice(idx + 1),
                })
              )
            }}
            posts={postsIds}
          />
          {!loading && data.search?.hasMore && (
            <Button onClick={loadMore}>{t('SEE_MORE')}</Button>
          )}
        </div>
      )}
    </Wrapper>
  )
}

export default Playlist

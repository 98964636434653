import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Grid, { GridSize } from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import {
  getCollectionEntities,
  getCollectionEntitiesVariables,
} from '~/queries'
import Typo from '~/components/Typo'
import { useTranslation } from '~/i18n'
import {
  TopDisappearingObserver,
  TopAppearingObserver,
} from '~/components/Observers'
import useMap from './useMap'
import GraphQLItem from '~/components/Item/GraphQL'
import useWidthRelatedToMap from '~/hooks/useWidthRelatedToMap'
import { useSelector } from '~/reducers'

interface Props {
  collectionGlobalId: string
}

function CollectedEntities({ collectionGlobalId }: Props) {
  const { t } = useTranslation('collection')
  const width = useWidthRelatedToMap()
  const seed = useSelector((state) => state.search.randomSeed)
  const { data, loading, fetchMore } = useQuery<
    getCollectionEntities,
    getCollectionEntitiesVariables
  >(eventsQuery, {
    variables: { collectionId: collectionGlobalId, seed },
  })
  const { loadMap, focusOn } = useMap(`text=${collectionGlobalId}`)

  if (!data?.collection?.entities?.edges.length) {
    return null
  }

  return (
    <div>
      <TopDisappearingObserver onChange={loadMap} />
      <Box mb={2}>
        <Typo variant="sectionStoryTitle">
          {t('collection:ENTITIES_TITLE')}
        </Typo>
      </Box>
      <Grid container spacing={5}>
        {data.collection.entities.edges.map((edge) => (
          <Grid item {...(width ? GRID[width] : {})} key={edge?.node?.id}>
            <div onMouseEnter={() => focusOn(edge?.node?.id!)}>
              <GraphQLItem globalId={edge?.node?.id} />
            </div>
          </Grid>
        ))}
      </Grid>
      {data?.collection?.entities?.pageInfo.hasNextPage && (
        <Box textAlign="center" my={3}>
          <Button
            variant="contained"
            size="large"
            disabled={loading}
            onClick={() => {
              fetchMore({
                variables: {
                  entityCursor: data?.collection?.entities?.pageInfo.endCursor,
                },
                updateQuery: (previousResults, { fetchMoreResult }) => {
                  const newEdges = fetchMoreResult?.collection?.entities?.edges
                  const pageInfo =
                    fetchMoreResult?.collection?.entities?.pageInfo
                  if (
                    !fetchMoreResult ||
                    !previousResults.collection?.entities ||
                    !newEdges ||
                    !pageInfo
                  ) {
                    return previousResults
                  }
                  return {
                    collection: {
                      ...previousResults.collection,
                      entities: {
                        ...previousResults.collection?.entities,
                        pageInfo,
                        edges: [
                          ...previousResults.collection?.entities?.edges,
                          ...newEdges,
                        ],
                      },
                    },
                  }
                },
              })
            }}
          >
            {t('common:SEE_MORE')}
          </Button>
        </Box>
      )}
      <TopAppearingObserver onChange={loadMap} />
    </div>
  )
}

export const eventsQuery = gql`
  query getCollectionEntities(
    $collectionId: ID!
    $entityTypes: [EntityType]
    $entityCursor: String
    $seed: Int
  ) {
    collection(id: $collectionId) {
      id
      entities(
        first: 24
        entityTypes: $entityTypes
        after: $entityCursor
        seed: $seed
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ... on Person {
              id
              slug
              name
              thumbnail
              birthDate
              deathDate
              birthPlace
            }
            ... on Group {
              id
              name
              slug
              thumbnail
              startDate
              endDate
              birthPlace
            }
            ... on Tag {
              id
              slug
              name
              thumbnail
              description
              locationName
              startDate
              endDate
            }
            ... on Instrument {
              id
              slug
              name
              thumbnail
            }
            ... on Track {
              id
              slug
              name
              thumbnail
              date
              authors {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              bands {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
            ... on Style {
              id
              slug
              name
              thumbnail
              locationName
              endDate
            }
            ... on PostList {
              id
              slug
              name
              thumbnail
              description
              createdBy {
                id
                slug
                displayName
              }
            }
          }
        }
      }
    }
  }
`

type GridSizes = {
  xs?: GridSize
  sm?: GridSize
  md?: GridSize
  lg?: GridSize
  xl?: GridSize
}
const GRID: {
  xs: GridSizes
  sm: GridSizes
  md: GridSizes
  lg: GridSizes
  xl: GridSizes
} = {
  xs: {
    xs: 12,
  },
  sm: {
    xs: 12,
    md: 6,
  },
  md: {
    xs: 12,
    sm: 6,
    md: 3,
  },
  lg: {
    xs: 12,
    sm: 6,
    md: 3,
  },
  xl: {
    xs: 12,
    sm: 6,
    md: 3,
  },
}

export default CollectedEntities

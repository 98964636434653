import { useDispatch } from '~/reducers'
import { setMarkersFromApiResults, focusOnGlobalId } from '~/ducks/map'
import { useCallback } from 'react'
import useFetch from '~/hooks/useFetch'
import MapType from '~/components/Map/Map'

const useMap = (searchApiQuery: string) => {
  const dispatch = useDispatch()
  const { data, mutate } = useFetch<MapType.ApiPayload>(
    `/api/v1/map/?${searchApiQuery}`,
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
    }
  )
  const loadMap = useCallback(() => {
    const handleData = (data: MapType.ApiPayload) =>
      dispatch(setMarkersFromApiResults(data?.results))
    if (!data) {
      return mutate().then((data) => {
        if (data) {
          handleData(data)
        }
      })
    } else {
      return handleData(data)
    }
  }, [data, dispatch, mutate])

  const focusOn = useCallback(
    async (globalId: string) => {
      await loadMap()
      dispatch(focusOnGlobalId(globalId))
    },
    [dispatch, loadMap]
  )

  return { loadMap, focusOn }
}

export default useMap

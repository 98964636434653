import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import Box from '@material-ui/core/Box'
import Head from 'next/head'
import { useDispatch } from 'react-redux'
import NextError from 'next/error'
import BoxMargin from '~/components/BoxMargin'
import BoxPadding from '~/components/BoxPadding'
import Playlist from '~/components/Card/Playlist'
import Typo from '~/components/Typo'
import Markdown from '~/components/Markdown'
import { getCollection, getCollectionVariables } from '~/queries'
import { setHeader } from '~/ducks/header'
import { useSelector } from '~/reducers'
import query from './query'
import Header from './Header'
import LinkToUserWithAvatar from '~/components/LinkToUserWithAvatar'
import Events from './Events'
import LayoutWithStickyMapOnTheSide from '../LayoutWithStickyMapOnTheSide'
import CollectedEntities from './CollectedEntities'
import Summary from './Summary'

interface Props {
  globalId: string
}

const CollectionPage = ({ globalId }: Props) => {
  const randomSeed = useSelector((state) => state.search.randomSeed)

  const { data, loading } = useQuery<getCollection, getCollectionVariables>(
    query,
    {
      variables: {
        globalId,
      },
    }
  )
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (data?.collection?.name) {
      dispatch(
        setHeader({
          title: 'Collection',
          subtitle: data?.collection.name,
        })
      )
    }
  }, [data, dispatch])

  if (!loading && !data?.collection) {
    if (process.browser) {
      return <NextError statusCode={404} />
    }
    const e = new Error('Collection not found')
    // @ts-ignore
    e.code = 'ENOENT'
    throw e
  }

  return (
    <React.Fragment>
      <Head>
        <title>{data?.collection?.name} - Collection - Mélo</title>
        <meta
          property="og:title"
          key="og:title"
          content={`${data?.collection?.name} - Collection - Mélo`}
        />
        <meta property="og:type" key="og:type" content="article" />
        {data?.collection?.body && (
          <meta
            property="og:description"
            key="og:description"
            content={
              data.collection.body.slice(0, 400) +
              (data.collection.body.length > 400 ? '...' : '')
            }
          />
        )}
        <meta property="og:type" key="og:type" content="article" />
        {data?.collection?.background?.renditions?.big && (
          <>
            <meta
              property="og:image"
              content={data.collection.background.renditions.big.href}
            />
            }
            <meta
              property="og:image:width"
              content={data.collection.background.renditions.big.width?.toString()}
            />
            }
            <meta
              property="og:image:height"
              content={data.collection.background.renditions.big.height?.toString()}
            />
            }
          </>
        )}
      </Head>
      <Header globalId={globalId} data={data} />
      <BoxMargin>
        <BoxPadding mt={4} textAlign="right">
          <LinkToUserWithAvatar
            user={data?.collection?.createdBy}
            size="big"
            reverse
          />
        </BoxPadding>
        <BoxPadding mt={4} mb={10}>
          {data?.collection?.body && (
            <Box mt={2} mb={10} maxWidth={720}>
              <Markdown md={data.collection.body} />
            </Box>
          )}
          <Summary globalId={globalId} />
        </BoxPadding>
      </BoxMargin>
      <LayoutWithStickyMapOnTheSide>
        <BoxPadding>
          <Events collectionGlobalId={globalId} />
          <Events collectionGlobalId={globalId} past />
          <div id="entities" style={{ position: 'absolute', marginTop: -80 }} />
          <CollectedEntities collectionGlobalId={globalId} />
        </BoxPadding>
      </LayoutWithStickyMapOnTheSide>
      <BoxMargin>
        <BoxPadding>
          <Box mt={10}>
            <Box mb={3}>
              <Typo variant="sectionEntityTitle">La radio</Typo>
            </Box>
            <Playlist
              entityId={globalId}
              playlistTitle={data?.collection?.name || 'Collection sans nom'}
              ordering={`?${randomSeed}`}
              limit={25}
            />
          </Box>
        </BoxPadding>
      </BoxMargin>
    </React.Fragment>
  )
}

export default CollectionPage

import PlayIcon from '@material-ui/icons/PlayArrow'
import React from 'react'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import { useTranslation } from '~/i18n'
import useCollectionPlayer from './useCollectionPlayer'
import { makeStyles } from '@material-ui/core'

interface Props {
  globalId: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    '& > *': {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}))

function Summary({ globalId }: Props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { playCollection } = useCollectionPlayer({ objectId: globalId })
  return (
    <div className={classes.root}>
      <Fab color="primary" variant={'extended'} onClick={playCollection}>
        <PlayIcon />
        {t('collection:PLAY_ALL_TOOLTIP')}
      </Fab>
      <Button size="large" variant="outlined" href="#entities">
        {t('collection:GO_TO_ENTITIES_SECTION_BUTTON_LABEL')}
      </Button>
    </div>
  )
}

export default Summary

import React from 'react'
import {
  searchAndPlayResults,
  setFullscreen as setFullscreenAction,
  togglePlay as togglePlayAction,
} from '~/ducks/player'
import { useDispatch } from 'react-redux'
import { useApolloClient } from '@apollo/react-hooks'
import query from './query'
import { getCollection, getCollectionVariables } from '~/queries'
import { useSelector } from '~/reducers'

interface Props {
  objectId: string
}

export default ({ objectId }: Props) => {
  const dispatch = useDispatch()
  const apolloClient = useApolloClient()
  const media = useSelector((state) => state.player.media)
  const isPlaying = useSelector((state) => state.player.isPlaying)
  const setFullscreen = React.useCallback(
    (fs) => dispatch(setFullscreenAction(fs)),
    [dispatch]
  )
  const togglePlay = React.useCallback((fs) => dispatch(togglePlayAction(fs)), [
    dispatch,
  ])
  const playCollection = React.useCallback(() => {
    return apolloClient
      .query<getCollection, getCollectionVariables>({
        query,
        variables: { globalId: objectId },
      })
      .then((res) => {
        return dispatch(
          searchAndPlayResults({
            objectId,
            playlistTitle: 'HOOK' + res.data?.collection?.name,
            random: true,
          })
        )
      })
  }, [dispatch, objectId, apolloClient])
  return { playCollection, setFullscreen, togglePlay, isPlaying, media }
}
